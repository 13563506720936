import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const analyticService = {
    getCart2,
    getCart3,
    getCart4,
    getAll,
    getUser,
    getDepartment,
};

function getCart4(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics4?${params}`, requestOptions).then(handleResponse);
}


function getCart3(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics3?${params}`, requestOptions).then(handleResponse);
}

function getCart2(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics2?${params}`, requestOptions).then(handleResponse);
}

function getAll(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?from=${from}&to=${to}`, requestOptions).then(handleResponse);
}

function getUser(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?gb=user&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}

function getDepartment(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?gb=department&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}
