import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Toolbar, AppBar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import ZnDateRange from 'common/znFields/ZnDateRange';
import moment from 'moment';
import PerformanceByDepartments from './components/Carts/PerformanceByDepartments';
import LeadsByCountryAndDept from './components/Carts/LeadsByCountryAndDept';
import { ZnDepartmentSimpleSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnDivider } from 'common/znFields/ZnDivider';
import { ZnSimpleSourceSelect, ZnSourceSelect } from 'common/znFields/ZnSourceSelect';
import { ZnButton } from 'common/znFields/ZnButton';



const defaultEndDate = new Date();
const defaultStartDate = new Date().setDate(defaultEndDate.getDate() - 365)



const useStyles = makeStyles(theme => ({
  cardbox: {
    maxWidth: '100px',
    backgroundColor: 'lightgray',
    borderRadius: 20
  },
  root: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));



const Analytics4 = () => {

  const classes = useStyles();

  const cartData = useSelector(state => state.analytics.cart4)
  const [filters, setFilters] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {

    const ff = filters;
    ff.from = moment(ff.startDate).format('YYYY-MM-DD')
    ff.to = moment(ff.endDate).format('YYYY-MM-DD')
    delete ff.startDate
    delete ff.endDate

    dispatch(analyticActions.getCart4(ff));
  }, [filters]);



  const handleDateRangeChange = (dateRange) => {
    setFilters({
      ...filters,
      startDate: dateRange.target?.value?.startDate,
      endDate: dateRange.target?.value?.endDate,
    })
  }

  const handleChangeDepartment = value => {
    console.log("handleChangeDepartment", value)
    setFilters({
      ...filters,
      departmentId: value
    })
  }

  const handleChangeSource = value => {
    setFilters({
      ...filters,
      sourceId: value
    })
  }

  const handleOnClickReset = () => {
    setFilters({})
  }



  return (
    <Fragment>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <ZnDateRange
            onChange={handleDateRangeChange}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
          <ZnDivider />
          <ZnDepartmentSimpleSelect
            fullWidth={false}
            value={filters.departmentId ?? null}
            onChange={handleChangeDepartment} />
          <ZnDivider />
          <ZnSimpleSourceSelect
            fullWidth={false}
            value={filters.sourceId ?? null}
            onChange={handleChangeSource} />


            <ZnButton onClick={handleOnClickReset}>reset</ZnButton>

        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>PERFORMANCE</Typography>
          </Grid>
          
          
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <PerformanceByDepartments
             title="Departments Performance" 
             subheader="performance by departments"
              categories={cartData?.performanceByDepartments?.categories}
              series={cartData?.performanceByDepartments?.series}
            />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <PerformanceByDepartments
             title="Users Performance" 
             subheader="performance by users"
              categories={cartData?.performanceByUsers?.categories}
              series={cartData?.performanceByUsers?.series}
            />
          </Grid>

          




        </Grid>
      </div>
    </Fragment>
  );
};

export default Analytics4;
