import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';



const PerformanceByDepartments = props => {
  const { series = [], categories = [], title, subheader } = props;




  const cartData = {
    options: {
      dataLabels: {
        enabled: true,
        //offsetX: 10,
        //offsetY: -20,
        style: {
          colors: ['#555'],
          // fontSize: '14px',
          fontWeight: 100,
        },
        formatter: (val) => {

          let minutes = val;
          let minutesRest = parseInt(val % 60);
          let hours = parseInt(val / 60);
          let hoursRest = parseInt(hours % 24);
          let days = parseInt(hours / 24);

           
          return `${days}d ${hoursRest}:${minutesRest}`;


          
          // let daysDec = val - daysInt;

          // let hours = 24 * daysDec;
          // let hoursInt = parseInt(hours);
          // let hoursDec = hours - hoursInt;

          // let minutes = 60 * hoursDec;
          // let minutesInt = parseInt(minutes);

          // hoursInt = ('' + hoursInt).padStart(2, 0);
          // minutesInt = ('' + minutesInt).padStart(2, 0);

          // const daysComp = daysInt > 0 ? `${daysInt}d` : '';
          // const timeComp = val > 0 ? `${hoursInt}:${minutesInt}` : '';

          // return `${daysComp} ${timeComp}`
        }
      },
      chart: {
        type: 'bar',
        stacked: true,
        id: "PerformanceByDepartments",

      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
          },
          barHeight: '90%',
          // distributed: true,
          horizontal: true,

        },

      },
    
      xaxis: {
        categories: categories,
        labels: {
          show: true,
          formatter: (val) => {

            
            // let valInt = parseInt(val);
            // let valDec = val - valInt;
            // // valDec = parseInt(valDec * 100);
            // let hours = 24 * valDec;
            // hours = parseInt(hours);

            const valInt = parseInt(val / 60 / 24);
            return `${valInt}`
          }
        }
      },
      yaxis: {
        
      }
    },
    series: series
  };


  return (categories && series) ?
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}>

      </CardHeader>
      <CardContent>
        <ReactApexChart
          type="bar"
          options={cartData.options}
          series={cartData.series}
          // height={1750} 
          />
      </CardContent>
    </Card> : <>loading</>

};

PerformanceByDepartments.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
};

export default PerformanceByDepartments;
