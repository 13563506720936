import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader, colors } from '@material-ui/core';

const LeadsByCountryAndDept = props => {
  const { series = [], categories = [], name = '' } = props;

  const cartData = {
    options: {
      chart: {
        type: 'donut',
        id: "LeadsByCountryAndDept",
      },
      colors: series.map(v => {
        const sum = series.reduce((partialSum, a) => partialSum + a, 0);
        return (v/sum > 0.05) ? '#29bc69' : '#BC2957';
      }),

      dataLabels: {
        // offsetY: -20,
        // style:{
        //   colors: [ "#000000"],
        //   fontSize: "12px",
        //   fontWeight: 400
        // }

      },
      xaxis: {
        categories: categories
      },
      labels: categories,

    },
    series: series
  };


  return (categories && series) ?
    <Card>
      <CardHeader title={name}
        subheader="All incoming leads by country">
      </CardHeader>
      <CardContent>
        {cartData.series.length > 0 ?
          <ReactApexChart
            type="donut"
            options={cartData.options}

            series={cartData.series}
            height={350} />
          : <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={307}
          >
            <Box>no data</Box>
          </Box>

        }
      </CardContent>
    </Card> : <>loading</>

};

LeadsByCountryAndDept.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
};

export default LeadsByCountryAndDept;
