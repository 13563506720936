import { analyticConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  all: [],
  user:[],
  department: [],
  loading: false,
  error: null,

};

export function analytics(state = initialStatus, action) {


  switch (action.type) {


   


    case analyticConstants.GET_CART_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case analyticConstants.GET_CART_2_SUCCESS: 
      return {
        ...state,
        cart2: action.analytics,
        loading: false
      };
      break;
    case analyticConstants.GET_CART_2_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


      case analyticConstants.GET_CART_3_REQUEST:
        return {
          ...state,
          loading: true
        };
      case analyticConstants.GET_CART_3_SUCCESS: 
        return {
          ...state,
          cart3: action.analytics,
          loading: false
        };
        break;
      case analyticConstants.GET_CART_3_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };

        case analyticConstants.GET_CART_4_REQUEST:
          return {
            ...state,
            loading: true
          };
        case analyticConstants.GET_CART_4_SUCCESS: 
          return {
            ...state,
            cart4: action.analytics,
            loading: false
          };
          break;
        case analyticConstants.GET_CART_4_FAILURE:
          return {
            ...state,
            error: action.error,
            loading: false
          };
  

    case analyticConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case analyticConstants.GETALL_SUCCESS: 
      return {
        ...state,
        all: action.analytics,
        loading: false
      };
      break;
    case analyticConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


      case analyticConstants.GETUSER_REQUEST:
        return {
          ...state,
          loading: true
        };
      case analyticConstants.GETUSER_SUCCESS: 
        return {
          ...state,
          user: action.analytics,
          loading: false
        };
        break;
      case analyticConstants.GETUSER_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };

      case analyticConstants.GETDEPARTMENT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case analyticConstants.GETDEPARTMENT_SUCCESS: 
        return {
          ...state,
          department: action.analytics,
          loading: false
        };
        break;
      case analyticConstants.GETDEPARTMENT_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };


    



    default:
      return state;
  }
}