import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';



const CartLeadsInDepartmentByTime = props => {
  const { series = [], categories = [], title, subheader } = props;

  const cartData = {
    options: {
      chart: {
        type: 'bar',
        id: "CartLeadsInDepartmentByTime",
      },
      dataLabels: {
        offsetY: -20,
        style:{
          colors: [ "#000000"],
          fontSize: "12px",
          fontWeight: 400
        }
       
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            orientation: 'horizontal',
          }
        }
      },
      xaxis: {
        categories: categories,
      },

    },
    series: series
  };


  return (categories && series) ?
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}>
      </CardHeader>
      <CardContent>
        <ReactApexChart
          type="bar"
          options={cartData.options}
          series={cartData.series}
          height={350} />
      </CardContent>
    </Card> : <>loading</>

};

CartLeadsInDepartmentByTime.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
};

export default CartLeadsInDepartmentByTime;
