import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Analytics as AnalyticsView,
  Survey as SurveyView,
  Source as SourceView,
  SourceWebsite as SourceWebsiteView,
  SourceForm as SourceFormView,
  User as UserListView,
  LeadsList as LeadListView,
  LeadsDetail as LeadDetailView,
  MessageModel as MessageModelListView,
  Attachment as AttachmentListView,
  Procedure as ProcedureListView,
  NewslettersList as NewsletterListView,
  Message as MessageListView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  LostPassword as LostPasswordView,
  NotFound as NotFoundView,
  Customer as CustomerView,
  Company as CompanyView,
  Tag as TagView,
  Analytics2
} from './views';
import Analytics3 from 'views/Analytics3';
import Analytics4 from 'views/Analytics4';


const Routes = () => {
  return (
    <Switch>
      {/* <Redirect
        exact
        from="/"
        to={`/sign-in2`}
      /> */}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        protected="true"
      />

      <RouteWithLayout
        component={CustomerView}
        exact
        layout={MainLayout}
        path="/customer/:id?"
        protected="true"
      />
      <RouteWithLayout
        component={TagView}
        exact
        layout={MainLayout}
        path="/tag/:id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyView}
        exact
        layout={MainLayout}
        path="/companies"
        protected="true"
      />

      <RouteWithLayout
        component={AnalyticsView}
        exact
        layout={MainLayout}
        path="/analytics"
        protected="true"
      />
       <RouteWithLayout
        component={Analytics2}
        exact
        layout={MainLayout}
        path="/analytics2"
        protected="true"
      />
      <RouteWithLayout
        component={Analytics3}
        exact
        layout={MainLayout}
        path="/analytics3"
        protected="true"
      />
       <RouteWithLayout
        component={Analytics4}
        exact
        layout={MainLayout}
        path="/analytics4"
        protected="true"
      />
      <RouteWithLayout
        component={SurveyView}
        exact
        layout={MainLayout}
        path="/survey"
        protected="true"
      />

      <RouteWithLayout
        component={SourceView}
        exact
        layout={MainLayout}
        path="/source"
        protected="true"
      />
      <RouteWithLayout
        component={SourceWebsiteView}
        exact
        layout={MainLayout}
        path="/source-website"
        protected="true"
      />
      <RouteWithLayout
        component={SourceFormView}
        exact
        layout={MainLayout}
        path="/source-form"
        protected="true"
      />

      {/* <RouteWithLayout
        component={Detail}
        exact
        layout={MainLayout}
        path="/detail"
        protected="true"
      /> */}
      <RouteWithLayout
        component={UserListView}
        layout={MainLayout}
        path="/users/:id?"
        protected="true"
      />
      {/* <RouteWithLayout
        component={LeadListView}
        exact
        layout={MainLayout}
        path="/leads"
        protected="true"
      /> */}
      <RouteWithLayout
        component={LeadDetailView}
        exact
        layout={MainLayout}
        path="/leads/:id"
        protected="true"
      />
      <RouteWithLayout
        component={MessageListView}
        exact
        layout={MainLayout}
        path="/messages/:id?"
        protected="true"
      />
      <RouteWithLayout
        component={MessageModelListView}
        exact
        layout={MainLayout}
        path="/message-model"
        protected="true"
      />
      <RouteWithLayout
        component={AttachmentListView}
        exact
        layout={MainLayout}
        path="/attachment"
        protected="true"
      />
      <RouteWithLayout
        component={ProcedureListView}
        exact
        layout={MainLayout}
        path="/procedure"
        protected="true"
      />
      <RouteWithLayout
        component={NewsletterListView}
        exact
        layout={MainLayout}
        path="/newsletter"
        protected="true"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
        protected="true"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={LostPasswordView}
        exact
        layout={MinimalLayout}
        path="/lost-password"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect exact from="*" to={`/dashboard`} />
      <Redirect to={`/not-found`} />
    </Switch>
  );
};

export default Routes;
